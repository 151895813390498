import { RouterProvider, createRouteMask, createRouter } from "@tanstack/react-router";
import { routeTree } from "./routeTree.gen";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { Toaster } from "./components/ui/toaster";
import { useAuth, AuthProvider } from "./hooks/auth";
import { HOUR } from "./constants";

const router = createRouter({
  routeTree,
  context: { authentication: undefined! },
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const InnerApp = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: HOUR,
        gcTime: HOUR * 24,
        retry: (failureCount, error) => {
          console.log("retry", failureCount, error);
          return failureCount < 1;
        },
      },
    },
  });
  const authentication = useAuth();
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} context={{ authentication }} />
      <Toaster />
    </QueryClientProvider>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <InnerApp />
    </AuthProvider>
  );
};

export default App;
