import _ from "lodash";
import Tooltip from "../Utils/Tooltip";
import { useMetricTileData } from "../../api/metricTileData";
import { formatDuration, getTileValueFormatter } from "../Utils/Formatters";
import { Skeleton } from "../ui/skeleton";

//   <% if metric.rising? && metric.desired_movement == 'up'%>
//     <span class="flex items-center gap-x-1 text-green-500">
//       <svg class="inline-block size-4 self-center" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><polyline points="22 7 13.5 15.5 8.5 10.5 2 17"/><polyline points="16 7 22 7 22 13"/></svg>
//       <span class="inline-block text-sm"><%= metric.difference_formatted %></span>
//     </span>
//   <% elsif metric.rising? && metric.desired_movement == 'down' %>
//     <span class="flex items-center gap-x-1 text-red-500">
//       <svg class="inline-block size-4 self-center" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><polyline points="22 7 13.5 15.5 8.5 10.5 2 17"/><polyline points="16 7 22 7 22 13"/></svg>
//       <span class="inline-block text-sm"><%= metric.difference_formatted %></span>
//     </span>
//   <% elsif metric.falling? && metric.desired_movement == 'up' %>
//     <span class="flex items-center gap-x-1 text-red-500">
//       <svg class="inline-block size-4 self-center" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><polyline points="22 17 13.5 8.5 8.5 13.5 2 7"/><polyline points="16 17 22 17 22 11"/></svg>
//       <span class="inline-block text-sm"><%= metric.difference_formatted %></span>
//     </span>
//   <% elsif metric.falling? && metric.desired_movement == 'down' %>
//     <span class="flex items-center gap-x-1 text-green-500">
//       <svg class="inline-block size-4 self-center" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><polyline points="22 17 13.5 8.5 8.5 13.5 2 7"/><polyline points="16 17 22 17 22 11"/></svg>
//       <span class="inline-block text-sm"><%= metric.difference_formatted %></span>
//     </span>
//   <% else %>
//     <span class="flex items-center gap-x-1 text-gray-500">
//       &#8212;
//       <span class="inline-block text-sm"><%= metric.difference_formatted %></span>
//     </span>
//   <% end %>
const MetricTile = ({ metricKey, teamId }: { metricKey: string; teamId: string | number }) => {
  const { isLoading, data, isError } = useMetricTileData({key: metricKey, teamId});
  if (isLoading) {
    return (
      <div className="px-4 py-8 space-y-2">
        <div className="flex items-center gap-x-4">
          <Skeleton className="w-32 h-7" />
          <Skeleton className="w-6 h-6" />
        </div>
        <div className="flex items-center gap-x-2 text-gray-400 dark:text-neutral-600">
          <Skeleton className="w-20 h-4" />
        </div>
      </div>
    )
  } else if (isError) {
    return <div className="flex h-full items-center justify-center">Failed to load data</div>;
  } else {
    const metric = _.find(data!.data, { type: "big_number" });
    const value_key = metric.dataset.dimensions[0]
    const value = metric.dataset.source[0][value_key]
    const formattedValue = getTileValueFormatter(metric.format)(value);
    return (
      <div className="px-4 py-8">
        <div className="flex items-center gap-x-4">
          <h3 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 sm:text-2xl">{formattedValue}</h3>
        </div>
        <div className="flex items-center gap-x-2 text-gray-400 dark:text-neutral-600">
          <p className="text-sm tracking-wide">{metric.name}</p>
          <Tooltip tooltip={metric.description} />
        </div>
      </div>
    );
  }
};

export default MetricTile;
