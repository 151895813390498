import { createFileRoute, useNavigate } from "@tanstack/react-router";
import FormIntegration from "./-FormIntegration";
import { useIntegrationData, useUpdateIntegration, useDeleteIntegration } from "@/api/Integrations";
import { integrationSchemas } from "./-integrationSchemas";

export const Route = createFileRoute("/_authenticated/settings/integrations/$id")({
  component: Integration,
});

function Integration() {
  const { id } = Route.useParams();
  const navigate = useNavigate();
  console.log(id);
  const { data: integration, isLoading } = useIntegrationData(id);
  const name = integration?.type;
  const updateIntegration = useUpdateIntegration();
  const deleteIntegration = useDeleteIntegration();

  const onSubmit = (data) => {
    updateIntegration.mutate({ integrationId: integration.id, data });
  };

  const handleDelete = () => {
    deleteIntegration.mutate(integration.id, {
      onSuccess: () => {
        navigate({ to: "/settings" });
      },
    });
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <FormIntegration
      name={name}
      defaultValues={integration}
      onSubmit={onSubmit}
      onDelete={handleDelete}
      schemaSettings={integrationSchemas[name]}
    />
  );
}
