import { createFileRoute, useLoaderData } from "@tanstack/react-router";
import FormIntegration from "./-FormIntegration";
import { useState } from "react";
import { useNavigate } from "@tanstack/react-router";
import { integrationSchemas } from "./-integrationSchemas";
import Spinner from "@/components/ui/spinner";
import { useCreateIntegration } from "@/api/Integrations";
import { Icons } from "@/components/Utils/Icons";
import _ from "lodash";
import { Integration } from "@/types/integration";

export const Route = createFileRoute("/_authenticated/settings/integrations/new")({
  component: NewIntegration,
});


function NewIntegration() {
  const [selectedIntegration, setSelectedIntegration] = useState<string | null>(null);
  const navigate = useNavigate();
  const { mutate, isLoading } = useCreateIntegration();
  const [_teams, integrations]: [any[], Integration[]] = useLoaderData({ from: "/_authenticated/settings" });
  const existingIntegrations = integrations.map((integration) => integration.type);

  const handleIntegrationSelect = (integration: string) => {
    if (!existingIntegrations.includes(integration)) {
      setSelectedIntegration(integration);
    }
  };

  const onSubmit = (data: any) => {
    mutate(data, {
      onSuccess: (integration) => {
        navigate({ to: `/settings/integrations/${integration.id}` });
      },
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (!selectedIntegration) {
    const icons = {
      github: <Icons.Github />,
      jira: <Icons.Jira />,
    }
    return (
      <div className="grid grid-cols-3 gap-4">
        {Object.keys(integrationSchemas).map((key) => {
          const isDisabled = existingIntegrations.includes(key);
          return (
            <div
              key={key}
              className={`integration-tile p-4 border rounded-lg shadow-md flex flex-col items-center justify-center ${
                isDisabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer hover:bg-gray-100"
              }`}
              onClick={() => handleIntegrationSelect(key)}
            >
              <div className="size-12 icon mb-2">
                {icons[key]}
              </div>
              <div className="text-center">
                <div className="font-bold">{_.capitalize(key)}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <FormIntegration
      name={selectedIntegration}
      onSubmit={onSubmit}
      onBack={() => setSelectedIntegration(null)}
      defaultValues={{}}
      schemaSettings={integrationSchemas[selectedIntegration]}
    />
  );
}
