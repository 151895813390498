import DeliverablesTable from '@/components/DeliverablesTable'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import Select from '@/components/Select'
import { useDeliverablesData } from '@/api/Deliverables'
import { handleIntegrationNotSetupError } from '@/lib/errorHandlers'

const DELIVERABLE_TYPES = [
  { value: 'epic', label: 'Epic' },
  { value: 'component', label: 'Component' },
]

const DEFAULT_TYPE = 'epic'

function DeliverableTypeSelect({
  value,
  onChange,
}: {
  value: string | undefined
  onChange: (value: string) => void
}) {
  return (
    <Select
      value={value}
      onChange={onChange}
      isLoading={false}
      placeholder="Select type"
      options={DELIVERABLE_TYPES}
    />
  )
}

export const Route = createFileRoute('/_authenticated/deliverables/')({
  component: Deliverables,
})

function Deliverables() {
  const { type: searchType } = Route.useSearch()
  const type = searchType || DEFAULT_TYPE
  const { data, isLoading, isError, error } = useDeliverablesData(type);
  const navigate = useNavigate()

  const integrationErrorComponent = handleIntegrationNotSetupError(isError, error);
  if (integrationErrorComponent) {
    return integrationErrorComponent;
  }

  const changeDeliverableType = (type: string) => {
    navigate({ to: Route.fullPath, search: { type }, replace: true })
  }

  return (
    <div className="flex h-full min-h-screen flex-col gap-6 bg-gray-100 p-6">
      <div className="flex items-center justify-between">
        <DeliverableTypeSelect value={type} onChange={changeDeliverableType} />
      </div>
      <DeliverablesTable data={data} isLoading={isLoading} isError={isError} />
    </div>
  )
}
