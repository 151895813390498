import { TooltipContent, TooltipProvider, TooltipTrigger, Tooltip as TooltipUI } from "@/ui/tooltip";
import { Icons } from "./Icons";

const Tooltip = ({ delayDuration = 500, tooltip }: { delayDuration?: number, tooltip: string }) => {
  return (
    <TooltipProvider>
      <TooltipUI delayDuration={delayDuration}>
        <TooltipTrigger className="text-gray-400 dark:text-neutral-600">
          <Icons.CircleHelp size={16} />
        </TooltipTrigger>
        <TooltipContent align="start" className="max-w-96">
          <p>{tooltip}</p>
        </TooltipContent>
      </TooltipUI>
    </TooltipProvider>
  );
};

export default Tooltip;
