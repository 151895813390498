import _ from "lodash";

export const percentageTransform = {
  type: "andonPulse:percent",
  transform: (params) => {
    const upstream = params.upstream;
    const config = params.config || {};
    const dimension = config.dimension;
    const metric = config.metric;
    const rawDataset = upstream.cloneRawData();
    const uniqGroups = _.map(_.uniqBy(rawDataset, dimension), dimension);
    let newDataset = [];
    _.map(uniqGroups, (group) => {
      const rowsInGroup = _.filter(rawDataset, [dimension, group]);
      const total = _.sumBy(rowsInGroup, metric);
      _.map(rowsInGroup, (row) => {
        const { [metric]: value, ...rest } = row;
        const formatted_value = _.round(value / total, 10);
        newDataset.push({ ...rest, [metric]: formatted_value });
      });
    });
    return [
      {
        dimensions: upstream.cloneAllDimensionInfo(),
        data: newDataset,
      },
    ];
  },
};

// Function to nullify the data if combination of dimensions is not present.
export const nullifyTransform = {
  type: "andonPulse:nullify",
  transform: (params) => {
    const upstream = params.upstream;
    const config = params.config || {};
    const dimensions = config.dimensions;
    const metric = config.metric;
    const rawDataset = upstream.cloneRawData();
    let newDataset: any[] = [];
    const combinations = allCombinations(dimensions, rawDataset);
    _.map(combinations, (group: any) => {
      const row = _.find(rawDataset, group);
      if (row) {
        newDataset.push(row);
      } else {
        newDataset.push({ ...group, [metric]: 0.0 });
      }
    });
    return [
      {
        dimensions: upstream.cloneAllDimensionInfo(),
        data: newDataset,
      },
    ];
  },
};

type DataRow = Record<string, any>;
type Dimension = string;

const allCombinations = (dimensions: Dimension[], rawDataset: DataRow[]): DataRow[] => {
  const uniqValues = rawDataset.reduce((acc: DataRow[], row: DataRow) => {
    _.forEach(dimensions, (dimension: Dimension) => {
      if (!acc.some((item: DataRow) => item[dimension] === row[dimension])) {
        acc.push({ [dimension]: row[dimension] });
      }
    });
    return acc;
  }, []);
  const uniqValueOfDimensions = dimensions.map((dimension: Dimension) => {
    const filter = _.filter(uniqValues, dimension);
    return _.map(filter, (item: DataRow) => _.pick(item, dimension));
  });
  const product = cartesianProduct(uniqValueOfDimensions);
  return _.map(product, (arr: DataRow[]) => Object.assign({}, ...arr));
};

const cartesianProduct = (arr: DataRow[][]): DataRow[][] => {
  return arr.reduce((res: DataRow[][], a: DataRow[]) => res.flatMap((x: DataRow[]) => a.map((y: DataRow) => [...x, y])), [[]]);
};