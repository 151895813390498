import { Link } from "@tanstack/react-router";

export const handleIntegrationNotSetupError = (isError: boolean, error: any) => {
  if (isError && error.response.data.error_code === "INTEGRATION_NOT_SETUP") {
    return (
      <div className="flex h-screen flex-col items-center justify-center gap-4">
        <div className="text-2xl text-gray-500">Integration not setup</div>
        <div className="text-xl text-gray-500">
          Please finish the&nbsp;
          <Link to="/settings/integrations/new" className="link">
            integration setup
          </Link>
          &nbsp;to use this feature
        </div>
      </div>
    );
  }
  return null;
};
