import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
// import { DatePicker } from "@/components/ui/datepicker";
import { FormField, FormLabel, FormMessage, FormItem, Form, FormControl } from "@/ui/form";
import Tooltip from "@/components/Utils/Tooltip";
import _ from "lodash";
import { IntegrationSchema } from "@/types/integration";

function FormIntegration({
  name,
  defaultValues,
  onSubmit,
  onDelete,
  onBack,
  schemaSettings,
}: {
  name: string;
  defaultValues: any;
  onSubmit: (data: any) => void;
  onDelete?: () => void;
  onBack?: () => void;
  schemaSettings: IntegrationSchema;
}) {
  const schema = z.object(
    schemaSettings.fields.reduce((acc, field) => {
      acc[field.key] = field.required ? z.string().min(1, `${field.name} is required`) : z.string().optional();
      return acc;
    }, {}),
  );

  const transformedDefaultValues = schemaSettings.fields.reduce((acc, field) => {
    acc[field.key] = defaultValues.settings?.find(attr => attr.key === field.key)?.value || '';
    return acc;
  }, {});

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: transformedDefaultValues,
  });

  const onSubmitTransformed = (data: any) => {
    const transformedData = {
      type: name,
      settings: Object.keys(data).map((key) => ({
        key,
        value: data[key],
      })),
    };
    onSubmit(transformedData);
  };

  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-2xl font-bold">{_.capitalize(name)} Integration</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmitTransformed)} className="space-y-4">
          {schemaSettings.fields.map((integrationField) => (
            <FormField
              control={form.control}
              key={integrationField.key}
              name={integrationField.key}
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="flex items-center gap-2">
                    {integrationField.name}
                    {integrationField.tooltip && <Tooltip delayDuration={0} tooltip={integrationField.tooltip} />}
                    {!integrationField.required && (
                      <span className="text-xs text-gray-400 dark:text-neutral-600">Optional</span>
                    )}
                  </FormLabel>
                  <FormControl>
                    <Input {...field} placeholder={integrationField.name} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          ))}
          <div className="space-x-2">
            <Button type="submit">Save</Button>
            {onBack && (
              <Button type="button" onClick={onBack} variant="outline">
                Back
              </Button>
            )}
            {onDelete && (
              <Button type="button" onClick={onDelete} variant="destructive">
                Delete
              </Button>
            )}
          </div>
        </form>
      </Form>
    </div>
  );
}

export default FormIntegration;
