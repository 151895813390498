import { createFileRoute, useNavigate } from "@tanstack/react-router";
import Spinner from "@/components/ui/spinner";
import FormTeam from "../-FormTeam";
import { useUpdateTeam, useTeamData, useDeleteTeam } from "@/api/Teams";
import { useMembersData } from "@/api/Members";

export const Route = createFileRoute("/_authenticated/settings/teams/$id/")({
  component: Team,
});

function Team() {
  const { id } = Route.useParams();
  const { data, isLoading } = useTeamData(id);
  const navigate = useNavigate();
  let defaultValues = {};
  let memberOptions = [];

  const { data: members, isLoading: isMembersLoading } = useMembersData();
  const { mutate } = useUpdateTeam();
  const { mutate: deleteTeam } = useDeleteTeam();

  if (isLoading || isMembersLoading) {
    return <Spinner />;
  } else {
    defaultValues.name = data.name;
    defaultValues.kind = data.kind;
    defaultValues.member_ids = data.members.map((member) => member.id.toString());
    memberOptions = members.map((member) => {
      return {
        value: member.id.toString(),
        label: member.name,
      };
    });
  }
  const onSubmit = (data) => {
    mutate({ id, data });
  };

  const onDelete = () => {
    deleteTeam(id);
    navigate({ to: "/settings" });
  };

  return (
    <FormTeam
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      memberOptions={memberOptions}
      SubmitText="Update Team"
      ResetText="Cancel"
      onDelete={onDelete}
    />
  );
}
