import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function calculatePercentages(numbers: number[]) {
  const total = numbers.reduce((sum: number, num: number) => sum + num, 0);
  if (total === 0) {
    return numbers.map(() => 0);
  }

  // Calculate raw percentages
  let percentages = numbers.map((num: number) => (num / total) * 100);

  // Round to 1 decimal place
  percentages = percentages.map((p: number) => Math.round(p * 10) / 10);

  // Handle rounding errors to ensure sum is 100%
  const sum = percentages.reduce((sum: number, p: number) => sum + p, 0);
  const diff = 100 - sum;
  
  // Add any rounding difference to the largest percentage
  if (diff !== 0) {
      const maxIndex = percentages.indexOf(Math.max(...percentages));
      percentages[maxIndex] = +(percentages[maxIndex] + diff).toFixed(1);
  }
  
  return percentages;
}

export function adjustPercentages(dataset: number[]): { [key: number]: number } {
  const total = dataset.reduce((sum: number, num: number) => sum + num, 0);
  const percentages = dataset.map((item) => [item, item * 100 / total]);

  const totalPercentage = percentages.reduce((sum: number, item) => sum + Math.floor(item[1]), 0);
  const sortedPercentages = percentages.sort((a, b) => (a[1] - Math.floor(a[1])) - (b[1] - Math.floor(b[1])));

  const diff = 100 - totalPercentage;

  const roundedPercentages = sortedPercentages.reduce((acc, item, index) => {
    const floorValue = Math.floor(item[1]);
    acc[item[0]] = index < diff ? floorValue + 1 : floorValue;
    return acc;
  }, {});

  return roundedPercentages;
}