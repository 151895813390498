import { useQuery } from "react-query";
import client from "./client";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { MetricData } from "../types/metric_data";
import { TIMESTAMP_FORMAT } from "@/constants";

dayjs.extend(utc);

interface MetricDataParams {
  key: string;
  viewIndex: number;
  from: string;
  to: string;
  teamId: string | number;
}

interface MetricDataResponse {
  data: MetricData[];
  error: string;
}

export const fetchMetricData = async ({
  key,
  viewIndex,
  from,
  to,
  teamId,
}: MetricDataParams): Promise<MetricDataResponse> => {
  const start_time = dayjs.utc(from).startOf("day").format(TIMESTAMP_FORMAT);
  const end_time = dayjs.utc(to).endOf("day").format(TIMESTAMP_FORMAT);
  const response = await client.post(`/metrics/${key}/data`, {
    view_index: viewIndex,
    filters: {
      start_time,
      end_time,
      team_id: teamId,
    },
  });
  return response.data;
};

export const useMetricData = ({ key, viewIndex, from, to, teamId }: MetricDataParams) => {
  return useQuery<MetricDataResponse, Error>({
    queryKey: ["metric_data", key, viewIndex, from, to, teamId],
    queryFn: () => fetchMetricData({ key, viewIndex, from, to, teamId }),
  });
};
