import { useMembersData } from "@/api/Members";
import { handleIntegrationNotSetupError } from "@/lib/errorHandlers";
import { createFileRoute, useNavigate } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/members/")({
  component: Members,
});

function Members() {
  const { data, isLoading, isError, error } = useMembersData();
  const navigate = useNavigate();

  const integrationErrorComponent = handleIntegrationNotSetupError(isError, error);
  if (integrationErrorComponent) {
    return integrationErrorComponent;
  }

  if (isLoading) {
    return null;
  }
  if (isError) {
    return <div>Error loading members</div>;
  }
  if (data?.[0]?.id) {
    navigate({
      to: "/members/$id",
      params: { id: data?.[0]?.id.toString() },
    });
  }
  return <div>No members found</div>;
}
