import { Integration } from "../types/integration";
import { useQuery, useMutation, useQueryClient, UseQueryResult } from "react-query";
import client from "./client";

// Fetch all integrations
export const useIntegrationsData = (): UseQueryResult<Integration[]> => {
  return useQuery<Integration[]>({
    queryKey: ["integrations"],
    queryFn: fetchIntegrations,
  });
};

// Fetch a single integration
export const useIntegrationData = (integrationId: number | string) => {
  return useQuery<Integration>({
    queryKey: ["integrations", integrationId],
    queryFn: async () => {
      const response = await client.get(`/integrations/${integrationId}`);
      return response.data;
    },
  });
};

// Create a new integration
export const useCreateIntegration = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newIntegration: Omit<Integration, "id">) => {
      const response = await client.post("/integrations", { integration: newIntegration });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["integrations"] });
    },
  });
};

// Update an existing integration
export const useUpdateIntegration = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ integrationId, data }: { integrationId: number | string; data: Partial<Integration> }) => {
      const response = await client.put(`/integrations/${integrationId}`, { integration: data });
      return response.data;
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ["integrations"] });
      queryClient.invalidateQueries({ queryKey: ["integrations", variables.integrationId] });
    },
  });
};

// Delete an integration
export const useDeleteIntegration = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (integrationId: number) => {
      await client.delete(`/integrations/${integrationId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["integrations"] });
    },
  });
};

export const fetchIntegrations = async (): Promise<Integration[]> => {
  const response = await client.get("/integrations");
  return response.data;
}; 