import { IntegrationSchema } from "@/types/integration";

export const integrationSchemas: Record<string, IntegrationSchema> = {
  github: {
    fields: [
      {
        name: "Access Token",
        key: "access_token",
        type: "string",
        required: true,
        tooltip:
          'Log into GitHub and then generate a personal access token. To load balance your API quota consumption across multiple API tokens, input multiple tokens separated with ","',
      },
      {
        name: "Repositories",
        key: "repositories",
        type: "array",
        required: true,
        tooltip:
          "List of GitHub organizations/repositories, e.g. `your-org/airbyte` for single repository, `your-org/*` for get all repositories from organization and `your-org/airbyte*` for matching multiple repositories by pattern.",
      },
      //   {
      //     name: "Start Date",
      //     key: "start_date",
      //     type: "datetime",
      //     required: false,
      //     tooltip:
      //       "The date from which you want to replicate data from Jira, use the format YYYY-MM-DDT00:00:00Z. Or leave it empty if you want to replicate all data.",
      //   },
    ],
  },
  jira: {
    fields: [
      {
        name: "API Token",
        key: "api_token",
        type: "string",
        required: true,
        tooltip:
          "Jira API Token. See the docs for more information on how to generate this key. API Token is used for Authorization to your account by BasicAuth.",
      },
      {
        name: "Domain",
        key: "domain",
        type: "string",
        required: true,
        tooltip:
          "The Domain for your Jira account, e.g. your-company.atlassian.net, your-company.jira.com, jira.your-domain.com",
      },
      {
        name: "Email",
        key: "email",
        type: "string",
        required: true,
        tooltip:
          "The user email for your Jira account which you used to generate the API token. This field is used for Authorization to your account by BasicAuth.",
      },
      //   {
      //     name: "Start Date",
      //     key: "start_date",
      //     type: "datetime",
      //     required: false,
      //     tooltip:
      //       "The date from which you want to replicate data from Jira, use the format YYYY-MM-DDT00:00:00Z. Or leave it empty if you want to replicate all data.",
      //   },
    ],
  },
};